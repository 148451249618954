import DocumentPDFV2 from 'components/ui/pdf/DocumentPDFV2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceToUpload } from 'types/entities/invoice';
import './styles.scss';

type Props = {
  documentsToUpload: InvoiceToUpload[];
};
const PDFViewer = ({ documentsToUpload }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const [documentIndex, setDocumentIndex] = useState(0);

  const handleNextDocument = () => {
    if (documentsToUpload && documentIndex < documentsToUpload.length - 1) {
      setDocumentIndex(documentIndex + 1);
    }
  };

  const handlePrevDocument = () => {
    if (documentsToUpload && documentIndex > 0) {
      setDocumentIndex(documentIndex - 1);
    }
  };

  const documentPDF =
    documentsToUpload && documentsToUpload.length > 0 ? (
      <DocumentPDFV2 file={documentsToUpload[documentIndex].file_url} />
    ) : null;

  if (!documentsToUpload || documentsToUpload.length === 0) {
    return null;
  }
  return (
    <div className='invoice-pdf-wrapper' style={{ height: '75vh' }}>
      {documentPDF}
      {documentsToUpload && documentsToUpload.length > 0 && (
        <div className='change-document-wrapper'>
          <img
            src='/images/icons/chevron.svg'
            className='chevron-icon-left'
            onClick={handlePrevDocument}
            alt='chevron'
          />
          <span className='subtitle3-font'>{`${t('invoice')} ${documentIndex + 1}/${
            documentsToUpload.length
          }`}</span>
          <img
            src='/images/icons/chevron.svg'
            className='chevron-icon-right'
            onClick={handleNextDocument}
            alt='chevron'
          />
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
