import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTreatmentSector from '../../../../customHooks/useTreatmentSector';
import {
  createFacility,
  createFacilityTreatment,
  getFacility,
  updateFacility
} from '../../../../services/api/facility';
import { Facility, WasteWaterTreatmentFacility } from '../../../../types/entities/facility';
import { User } from '../../../../types/entities/user';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import SelectCardWrapper from '../../../ui/selectCard/SelectCardWrapper';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';
import Tooltip from '../../../ui/tooltip/Tooltip';
import BaseForm from './baseForm/BaseForm';
import CategoryToggle from './baseForm/components/CategoryToggle';
import useCategories from './hooks/useCategories';
import './styles.scss';
import WasteWaterTreatmentForm from './wateWaterTreatmentForm/WasteWaterTreatmentForm';
import { getCountries } from '../../../../constants/phonePrefixes';

type Props = {
  mode: 'create' | 'edit';
  addFacility?: (value: Facility | WasteWaterTreatmentFacility) => void;
  editFacility?: (value: Facility | WasteWaterTreatmentFacility, id: string) => void;
  facilityId?: string;
  user: User | null;
};

export type FacilityForm = {
  typeFacility: {
    id: string;
    icon: string;
    title: string;
  };
  type: SelectOptionFormat;
  name: string;
  country: SelectOptionFormat;
  categories: SelectOptionFormat[];
  errors: ErrorType[];
};

export interface TreatmentPlantForm extends FacilityForm {
  waterLine: SelectOptionFormat;
  sludgeLine: SelectOptionFormat;
  waterDischargeLine: SelectOptionFormat;
  methaneBurn: boolean;
}

// in case of new facility type, create a new interface that extends of FacilityForm and add it into BodyForm
type BodyForm = FacilityForm & TreatmentPlantForm;

function EditCreateFacility({ mode, addFacility, facilityId, user, editFacility }: Props) {
  const { t, i18n } = useTranslation();
  const { isTreatmentOrganization } = useTreatmentSector();

  const categoriesToInclude = useCategories();
  const cards = [
    {
      id: 'facilities',
      icon: '/images/icons/building.svg',
      title: t('facilities.officeSelect')
    },
    {
      id: 'waste_water_facilities',
      icon: '/images/icons/road.svg',
      title: t('facilities.treatmentPlantSelect')
    }
  ];

  const typeOptions = [
    {
      id: 'rented',
      name: t('facilities.rented')
    },
    {
      id: 'owned',
      name: t('facilities.owned')
    }
  ];

  const [bodyForm, setBodyForm] = useState<BodyForm>({
    typeFacility: cards[0],
    type: { id: '', name: '' },
    name: '',
    country: { id: '', name: '' },
    waterLine: { id: '', name: '' },
    sludgeLine: { id: '', name: '' },
    waterDischargeLine: { id: '', name: '' },
    methaneBurn: false,
    categories: categoriesToInclude,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleSubmit();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  useEffect(() => {
    const getFacilityToEdit = async () => {
      if (!facilityId || !user?.selectedOrganization) return;
      let newFacilityToEdit: Facility | WasteWaterTreatmentFacility;
      try {
        newFacilityToEdit = await getFacility(facilityId, user.selectedOrganization);
      } catch (err) {
        return;
      }
      setBodyForm({
        typeFacility:
          cards.find((card) => card.id === newFacilityToEdit.facility_purpose_type) || cards[0],
        type: typeOptions?.find((option) => option.id === newFacilityToEdit.type) || {
          id: '',
          name: ''
        },
        name: newFacilityToEdit.name,
        country: getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase()).find(
          (element) => element.id.toUpperCase() === newFacilityToEdit.country.toUpperCase()
        ) || { id: '', name: '' },
        waterLine: (newFacilityToEdit as WasteWaterTreatmentFacility).wwt_line
          ? {
              id: (newFacilityToEdit as WasteWaterTreatmentFacility).wwt_line.id,
              name: t(
                `wwLineValues.${
                  (newFacilityToEdit as WasteWaterTreatmentFacility).wwt_line.line_code
                }`
              )
            }
          : { id: '', name: '' },
        sludgeLine: (newFacilityToEdit as WasteWaterTreatmentFacility).sludge_line
          ? {
              id: (newFacilityToEdit as WasteWaterTreatmentFacility).sludge_line.id,
              name: t(
                `wwLineValues.${
                  (newFacilityToEdit as WasteWaterTreatmentFacility).sludge_line.line_code
                }`
              )
            }
          : { id: '', name: '' },
        waterDischargeLine: (newFacilityToEdit as WasteWaterTreatmentFacility).wwd_line
          ? {
              id: (newFacilityToEdit as WasteWaterTreatmentFacility).wwd_line.id,
              name: t(
                `wwLineValues.${
                  (newFacilityToEdit as WasteWaterTreatmentFacility).wwd_line.line_code
                }`
              )
            }
          : { id: '', name: '' },
        methaneBurn: (newFacilityToEdit as WasteWaterTreatmentFacility).methane_burned ?? false,
        categories: newFacilityToEdit.categories.map((elem) => ({
          id: elem === 'waste' ? 'wastes' : elem,
          name: t(`facilityDetail.${elem}`)
        })),
        errors: []
      });
    };

    getFacilityToEdit();
  }, [facilityId]);

  useEffect(() => {
    setBodyForm((prev) => ({
      ...prev,
      categories: categoriesToInclude
    }));
  }, [JSON.stringify(categoriesToInclude)]);

  const onClickCard = (id: string) => {
    const newErrors = bodyForm.errors.filter((elem) => elem.error !== 'type');
    const found = cards.find((card) => card.id === id);
    if (found) {
      setBodyForm((prev) => ({ ...prev, typeFacility: found, errors: newErrors }));
      // if(found.id === 'waste_water_facilities'){
      //   setBodyForm((prev) => ({ ...prev, categories: [categoriesToInclude }));
      // }
    }
  };

  const handleErrors = async () => {
    const optionalFields =
      bodyForm.typeFacility.id === 'waste_water_facilities'
        ? ['methaneBurn']
        : ['waterLine', 'sludgeLine', 'methaneBurn'];
    const newErrors: ErrorType[] = checkFormErrors(bodyForm, [], optionalFields);

    try {
      // const addressValidation = await validateAddress(bodyForm.address, t);
      // if (addressValidation) {
      //   newErrors.push(addressValidation);
      // }
    } catch (error) {
      newErrors.push({ error: 'invalidAddress', description: `${t('error.invalidAddress')}` });
    }

    if (newErrors.length > 0) {
      setBodyForm((prev) => ({ ...prev, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  // CREATE OR EDIT
  const handleCreateFacility = async () => {
    const newErrors = await handleErrors();
    if (newErrors) return;
    if (!user?.selectedOrganization) return;
    setLoadingButton(true);

    const facilityCreated = await createFacility({
      name: bodyForm.name,
      type: bodyForm.type.id,
      country: bodyForm.country.id,
      organization_id: user.selectedOrganization,
      categories: bodyForm.categories.map((elem) => elem.id)
    });
    if (facilityCreated && addFacility) {
      addFacility(facilityCreated);
    }

    setLoadingButton(false);
  };

  const handleCreateTreatmentPlant = async () => {
    const newErrors = await handleErrors();
    if (newErrors) return;
    if (!user?.selectedOrganization) return;
    setLoadingButton(true);
    const facilityCreated = await createFacilityTreatment({
      name: bodyForm.name,
      type: bodyForm.type.id,
      country: bodyForm.country.id,
      wwt_line_id: bodyForm.waterLine.id,
      sludge_line_id: bodyForm.sludgeLine.id,
      wwd_line_id: bodyForm.waterDischargeLine.id,
      methane_burned: bodyForm.methaneBurn,
      categories: bodyForm.categories.map((elem) => elem.id)
    });
    if (facilityCreated && addFacility) {
      addFacility(facilityCreated);
    }
    setLoadingButton(false);
  };

  const handleEditFacility = async () => {
    const newErrors = await handleErrors();
    if (newErrors) return;
    if (!facilityId) return;
    if (!user?.selectedOrganization) return;

    setLoadingButton(true);
    const facilityUpdated = await updateFacility(facilityId, {
      name: bodyForm.name,
      type: bodyForm.type.id,
      categories: bodyForm.categories.map((elem) => elem.id)
    });
    if (editFacility && facilityUpdated) {
      editFacility(facilityUpdated, facilityId);
    }
    setLoadingButton(false);
  };

  const handleSubmit = () => {
    if (mode === 'create' && bodyForm.typeFacility.id === 'facilities') handleCreateFacility();
    if (mode === 'create' && bodyForm.typeFacility.id === 'waste_water_facilities')
      handleCreateTreatmentPlant();
    if (mode === 'edit') handleEditFacility();
  };

  const typeIconSelect = (type: string, selected: boolean) => {
    const images = {
      heat: selected ? '/images/icons/fire50.svg' : '/images/icons/fireGray.svg',
      recharge: selected ? '/images/icons/velocimeter.svg' : '/images/icons/velocimeterGray.svg',
      electricity: selected ? '/images/icons/thunder50.svg' : '/images/icons/thunderGray.svg',
      water: selected ? '/images/icons/water50.svg' : '/images/icons/waterGray.svg',
      waste_water_treatment: selected ? '/images/icons/road.svg' : '/images/icons/roadGray.svg',
      wastes: selected ? '/images/icons/waste.svg' : '/images/icons/wasteGray.svg'
    };

    const src = images[type as keyof typeof images] || null;

    if (!src) return <></>;
    return <img src={src} alt={type} width={16} height={16} />;
  };

  const handleSelectCategory = (category: SelectOptionFormat) => {
    // if category id already in form, remove, else add
    const newCategories = [...bodyForm.categories];
    const index = newCategories.findIndex((elem) => elem.id === category.id);

    // do not remove if it is the only category left
    if (newCategories.length === 1 && index !== -1) return;
    if (index !== -1) {
      newCategories.splice(index, 1);
    } else {
      newCategories.push(category);
    }
    setBodyForm((prev: any) => ({ ...prev, categories: newCategories }));
  };

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: [
      {
        id: 'default',
        text: t('facilities.default')
      },
      {
        id: 'custom',
        text: t('facilities.custom')
      }
    ]
  });

  const title = mode === 'edit' ? t('facilities.editFacility') : t('facilities.createNewFacility');
  const description =
    mode === 'edit'
      ? t('facilities.editFacilityDescription')
      : t('facilities.createNewFacilityDescription');

  const buttonText = mode === 'edit' ? t('facilities.save') : t('facilities.create');

  return (
    <div className='new-facility'>
      <FormHeader title={title} description={description} />
      {isTreatmentOrganization && (
        <div className='facility-type'>
          <h3 className='body1-bold-font'>{t('facilities.facilityType')}</h3>
          <SelectCardWrapper
            cards={cards}
            onClickCard={onClickCard}
            type={bodyForm.typeFacility}
            mode={mode}
          />
        </div>
      )}
      <div style={{ overflowY: 'auto', maxHeight: '50vh' }}>
        <StepCarouselList steps={steps} handleSelect={handleSelectCarousel} lookAndFeel={'small'} />

        <BaseForm
          bodyForm={bodyForm}
          setFormData={setBodyForm}
          mode={mode}
          typeOptions={typeOptions}
          typeId={bodyForm.typeFacility.id}
        />
        {bodyForm.typeFacility.id === 'waste_water_facilities' ? (
          <WasteWaterTreatmentForm bodyForm={bodyForm} setFormData={setBodyForm} mode={mode} />
        ) : null}
        {stepSelected?.id === 'custom' && (
          <>
            <span className='body1-bold-font'>{t('facilities.selectCategories')}</span>
            <div className='categories-to-include' style={{ marginTop: '1rem' }}>
              {categoriesToInclude.map((category) => {
                return (
                  <div className='categories-to-include__item' key={category.id}>
                    <div className='icon-text-wrapper'>
                      {typeIconSelect(
                        category.id,
                        !!bodyForm.categories.find((elem) => elem.id === category.id)
                      )}
                      <span className='body1-font' style={{ width: '4rem' }}>
                        {category.name}
                      </span>
                      <Tooltip text={t(`facilities.${category.id}Tooltip`)} />
                    </div>
                    <CategoryToggle
                      selected={!!bodyForm.categories.find((elem) => elem.id === category.id)}
                      handleSelectCategory={handleSelectCategory}
                      category={category}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={buttonText}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default EditCreateFacility;
