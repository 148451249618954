import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useGetData from '../../createInvoice/hooks/useGetData';
import { Facility } from 'types/entities/facility';
import { FormDataUploadInvoices } from '../UploadInvoices';
import { InvoiceAuto, InvoiceToUpload } from 'types/entities/invoice';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { useTranslation } from 'react-i18next';
import { FacilityFuelType } from 'types/entities/facilityFuel';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import DivideConsumptionsWrapper from '../../createInvoice/components/common/divideConsumptions/DivideConsumptionsWrapper';
import Button from 'components/ui/button/Button';
import { MAX_INVOICES_PER_REQUEST, MAX_PAYLOAD_SIZE } from '../../../../../../../constants';
import {
  validateFacilityWithoutId,
  validateFacilityWithZero
} from '../../createInvoice/components/validations/validator';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import ErrorText from 'components/ui/errorText/ErrorText';
import makeRequestsInBatches from 'utils/makeRequestInBatches';
import { uploadInvofox } from 'services/api/invoices';
import UploadFile from '../common/uploadFile/UploadFile';
import checkFormErrors from 'utils/checkFormErrors';

type Props = {
  facility: Facility;
  formData: FormDataUploadInvoices;
  setFormData: Dispatch<SetStateAction<FormDataUploadInvoices>>;
  documentsToUpload: InvoiceToUpload[];
  setDocumentsToUpload: Dispatch<SetStateAction<InvoiceToUpload[]>>;
  addInvoice: (invoicesUploaded: InvoiceAuto[]) => void;
};
const UploadWaterInvoices = ({
  facility,
  formData,
  setFormData,
  documentsToUpload,
  addInvoice,
  setDocumentsToUpload
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });
  const { organizationOptions, loadingData, lastUsedFacilityPercentages } = useGetData({
    type: formData.type,
    country: facility.country,
    facilityId: facility.id
  });

  const [sizesBiggerThanMax, setSizesBiggerThanMax] = useState<{ size: number; index: number }[]>(
    []
  );
  const [loadingButton, setLoadingButton] = useState(false);

  const buttonTooltip =
    formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0) === 0
      ? t('noPercentageAssignedYet')
      : t('percentageError');

  useEffect(() => {
    if (lastUsedFacilityPercentages.length > 0) {
      setFormData((prev) => ({
        ...prev,
        percentages: lastUsedFacilityPercentages.map((elem) => ({
          facility: {
            id: elem.facility_id,
            name: elem.facility_name
          },
          organization: {
            id: elem.organization_id,
            name: elem.company_name
          },
          percentage: (elem.percentage * 100).toString()
        }))
      }));
    }
  }, [JSON.stringify(lastUsedFacilityPercentages)]);

  const handleUploadInvoice = async () => {
    const optionalFields = ['supplier', 'facilityFuel'];

    // Check if there are errors in the form
    const newErrors = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    let size = 0;
    let sizeBase64 = 0;

    const sizes: number[] = [];
    documentsToUpload.forEach((invoice) => {
      size += invoice.file.size / (1024 * 1024);
      sizeBase64 = invoice.file_url.length / (1024 * 1024);
      sizes.push(sizeBase64);
    });

    const sizesBiggerThanMaxList: {
      size: number;
      index: number;
    }[] = [];
    sizes.forEach((elem, index) => {
      if (elem >= MAX_PAYLOAD_SIZE) {
        sizesBiggerThanMaxList.push({
          size: elem,
          index
        });
      }
    });

    setSizesBiggerThanMax(sizesBiggerThanMaxList);

    if (!documentsToUpload || documentsToUpload.length === 0 || sizesBiggerThanMax.length > 0) {
      newErrors.push({
        error: 'file',
        description: tError('maxPayloadSize')
      });
    }
    if (documentsToUpload.length > MAX_INVOICES_PER_REQUEST) {
      newErrors.push({
        error: 'file',
        description: tError('maxInvoicesPerRequest')
      });
    }

    const facilityWithZeroError = validateFacilityWithZero(formData.percentages);
    if (facilityWithZeroError) {
      newErrors.push(facilityWithZeroError);
    }

    const facilityWithoutIdError = validateFacilityWithoutId(formData.percentages);
    if (facilityWithoutIdError) {
      newErrors.push(facilityWithoutIdError);
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);

    const uploadRequests: any = [];

    documentsToUpload.forEach((invoice) => {
      uploadRequests.push(() =>
        uploadInvofox(
          {
            ...invoice,
            facility_percentages: formData.percentages.map((percentage) => ({
              organization_id: percentage.organization.id,
              facility_id: percentage.facility.id,
              percentage: parseFloat(percentage.percentage) / 100
            }))
          },
          true
        )
      );
    });

    const invoicesUploaded: any[] = [];

    try {
      const responses = await makeRequestsInBatches(uploadRequests);
      responses.forEach((response: any, index: number) => {
        if (
          response &&
          response.response &&
          response.response.status &&
          response.response.status === 413
        ) {
          sizesBiggerThanMaxList.push({
            size: sizes[index],
            index
          });
          // continue loop to next iteration
          return;
        }
        const invoice = response.data[0];
        invoicesUploaded.push({
          type: invoice.type,
          supplier: (invoice.supplier as SupplierType) || null,
          facility_fuel: invoice.facility_fuel as FacilityFuelType,
          status: 'loading',
          unit: invoice.unit as Unit,
          id: invoice.id,
          start_date: invoice.start_date,
          end_date: invoice.end_date,
          invoice_id: invoice.invoice_id,
          percentage:
            formData.percentages.find((elem) => elem.facility.id === facility.id)?.percentage ?? 100
        });
      });
      if (sizesBiggerThanMaxList.length > 0) {
        setSizesBiggerThanMax(sizesBiggerThanMaxList);
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: tError('maxPayloadSize') }]
        }));
        setLoadingButton(false);
        return;
      }
      addInvoice(invoicesUploaded);
      setLoadingButton(false);
    } catch (err: any) {
      let responseStatus = 200;
      if (err.response && err.response.status) {
        responseStatus = err.response.status;
      }
      if (responseStatus === 413) {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: tError('maxPayloadSize') }]
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: tError('uploadInvoices') }]
        }));
      }
      setLoadingButton(false);
    }
  };

  return (
    <>
      {loadingData ? (
        <CustomSkeletonLoader count={2} />
      ) : (
        <FormWrapper style={{ width: '584px', maxHeight: '45vh', overflowY: 'auto' }}>
          <UploadFile
            formData={formData}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            facility={facility}
            setFormData={setFormData}
          />
          {!facility.supercharger && (
            <DivideConsumptionsWrapper
              setFormData={setFormData}
              organizationOptions={organizationOptions}
              formData={formData}
              units={[]}
              facility={facility}
            />
          )}
        </FormWrapper>
      )}
      <FormButtonSection>
        <TooltipWrapper
          text={buttonTooltip}
          shouldAppear={!!formData.errors.find((elem) => elem.error === 'percentages')}
          style={{ width: '100%' }}>
          <Button
            lookAndFeel='primary'
            text={t('uploadInvoiceButton')}
            onClick={handleUploadInvoice}
            loading={loadingButton}
            disabled={!!formData.errors.find((error) => error.error === 'percentages')}
          />
        </TooltipWrapper>
      </FormButtonSection>
      {formData.errors.find((error) => error.error === 'request') && (
        <ErrorText>
          {formData.errors.find((error) => error.error === 'request')?.description}
        </ErrorText>
      )}
    </>
  );
};

export default UploadWaterInvoices;
