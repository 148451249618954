import InputFile from 'components/ui/formComponents/inputFile/InputFile';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { InvoiceToUpload } from 'types/entities/invoice';
import getBase64 from 'utils/getBase64';
import { FormDataUploadInvoices } from '../../UploadInvoices';
import { UserContext } from 'context/userContext';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useTranslation } from 'react-i18next';
import { InvoiceStatus } from 'types/entitiesEnums/invoice';
import { Facility } from 'types/entities/facility';
import FileItem from 'components/ui/fileItem/FileItem';
import './styles.scss';
type Props = {
  formData: FormDataUploadInvoices;
  documentsToUpload: InvoiceToUpload[];
  setDocumentsToUpload: Dispatch<SetStateAction<InvoiceToUpload[]>>;
  facility: Facility;
  setFormData: Dispatch<SetStateAction<FormDataUploadInvoices>>;
};
const UploadFile = ({
  formData,
  documentsToUpload,
  setDocumentsToUpload,
  facility,
  setFormData
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const getIconSrc = (fileType: string) => {
    if (fileType === 'heat') {
      return '/images/icons/fire50.svg';
    } else if (fileType === 'electricity') {
      return '/images/icons/thunder50.svg';
    } else if (fileType === 'water') {
      return '/images/icons/water.svg';
    }
    return '';
  };

  const handleUploadFiles = (filesUploaded: File[]) => {
    filesUploaded.forEach((file) => {
      getBase64(file, (file_url: string | null | ArrayBuffer) => {
        if (file_url) {
          const newInvoiceToUpload: InvoiceToUpload = {
            supplier_id: (formData.supplier as SelectOptionFormat).id
              ? (formData.supplier as SelectOptionFormat).id
              : null,
            facility_fuel_id: (formData.facilityFuel as SelectOptionFormat).id
              ? (formData.facilityFuel as SelectOptionFormat).id
              : null,
            file,
            type: formData.type as string,
            file_url: file_url as string,
            facility_id: facility.id || '',
            uploaded_by: user ? user.id : '',
            toc: formData.toc?.id,
            facility_percentages: [
              {
                organization_id: selectedOrganization?.id ?? '',
                facility_id: facility.id,
                percentage: 100
              }
            ],
            status: InvoiceStatus.LOADING
          };
          setDocumentsToUpload((prev) => [...prev, newInvoiceToUpload]);
        }
      });
    });
  };

  const handleRemoveDocument = (index: number) => {
    // filter errors to remove the one of type "file"
    const newErrors = formData.errors.filter((elem) => elem.error !== 'file');
    const oldInvoicesToUpload = [...documentsToUpload];
    oldInvoicesToUpload.splice(index, 1);
    setDocumentsToUpload(oldInvoicesToUpload);
    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));
  };

  return (
    <div className='flex-col gap-2' style={{ width: '100%' }}>
      <span className='body1-bold-font'>
        {documentsToUpload && documentsToUpload.length > 0
          ? t('invoiceUploaded')
          : t('uploadYourInvoices')}
      </span>
      {documentsToUpload && documentsToUpload.length > 0 && (
        <div className='files-wrapper'>
          {documentsToUpload.map((elem: InvoiceToUpload, index: number) => (
            <FileItem
              key={index}
              file={elem.file}
              index={index}
              icon={getIconSrc(elem.type)}
              removeFile={handleRemoveDocument}
            />
          ))}
        </div>
      )}
      <div className='file-upload-wrapper' style={{ width: '100%' }}>
        <InputFile
          handleFiles={handleUploadFiles}
          buttonText={
            documentsToUpload && documentsToUpload.length > 0
              ? t('uploadAnotherInvoice')
              : t('uploadInvoiceButtonFile')
          }
          fileNames={
            documentsToUpload && documentsToUpload.length > 0
              ? documentsToUpload.map((invoice) => invoice.file.name)
              : []
          }
          labelText={t('dragAndDropFile')}
          allowedTypes={['application/pdf']}
          error={formData.errors.find((error) => error.error === 'file')}
        />
      </div>
    </div>
  );
};

export default UploadFile;
