import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadUser } from '../../../actions/auth';
import { AMAZON_QUERY_PARAMS } from '../../../constants/amzAuth';
import { ROUTES } from '../../../constants/routes';
import useAmzAuth from '../../../customHooks/useAmzAuth';
import controller from '../../../environments/controller';
import { authLogin } from '../../../services/authService';
import { Session } from '../../../types/entities/user';
import { getQueryParams } from '../../../utils/getQueryParams';
import Button from '../../ui/button/Button';
import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import './styles.scss';
import apiFetch from 'utils/apiFetch';

const environment = controller();

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  loadUser
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Login = ({ loadUser }: Props) => {
  const navigate = useNavigate();
  const params = getQueryParams();

  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem('dashboard');
    localStorage.removeItem('user');
    localStorage.removeItem('hideSurveyModal');

    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      navigate(ROUTES.SIGNUP, { replace: true });
    }
  }, []);

  const amazon_callback_uri = params.get(AMAZON_QUERY_PARAMS.CALLBACK_URI_PARAM);
  const amazon_state = params.get(AMAZON_QUERY_PARAMS.STATE_PARAM);
  if (amazon_callback_uri && amazon_state) {
    useAmzAuth({
      amazon_callback_uri,
      amazon_state
    });
  }

  const handleLogin = async () => {
    /**
    We do not need to handle any logic here as the login call will redirect the browrser to the authentication provider
    */
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      const sessionData: Session = {
        user_id: '',
        email: '',
        id: ''
      };
      const response = await apiFetch('GET', '/auth');
      if (response) {
        sessionData.email = response.data.email;
        sessionData.id = response.data.id;
      }
      loadUser(sessionData);

      navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true });
    } else {
      authLogin();
    }
  };

  const handleSignup = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      navigate(ROUTES.SIGNUP, { replace: true });
    } else {
      const url = `https://${environment.amplify.Auth.oauth.domain}/signup?redirect_uri=${environment.amplify.Auth.oauth.redirectSignIn}&response_type=code&client_id=${environment.amplify.Auth.userPoolWebClientId}&identity_provider=COGNITO&scope=email%20openid`;
      window.open(url, '_self');
    }
  };

  return (
    <OnboardingImageWrapper
      title={t('login.title')}
      subtitle={t('login.subtitle')}
      img={'/images/previewApp.png'}>
      <div className='login-form'>
        <div className='buttons'>
          <Button lookAndFeel='primary' text={t('login.signUpNewAccount')} onClick={handleSignup} />
          <Button lookAndFeel='secondary' text={t('login.signInEmail')} onClick={handleLogin} />
        </div>
        <div className='login-form__footer'>
          <span className='body1-font on-light-text-color'>{t('login.accessInDesktop')}</span>
          <img src='/images/icons/mobileRotate.svg' alt='mobile rotate' />
        </div>
      </div>
    </OnboardingImageWrapper>
  );
};

export default connector(Login);
