import { useEffect } from 'react';
import Router from './components/layout/router/Router';
import './styles/index.scss';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { User } from './types/entities/user';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const App = () => {
  const ldClient = useLDClient();
  const user: User | null = useSelector((state: any) => state.auth.user, _.isEqual);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  useEffect(() => {
    if (!user || !foundOrganization) return;
    ldClient?.identify({
      kind: 'multi',
      organization: {
        key: foundOrganization?.id,
        name: foundOrganization?.company_name,
        sector: foundOrganization?.sector,
        partner: foundOrganization?.partner
      },
      user: {
        key: user?.id,
        user_email: user?.email
      }
    });
  }, [user]);

  return <Router />;
};

export default App;
