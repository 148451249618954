import { TFunction } from 'react-i18next';
export default function checkFormErrors(
  formData: Record<string, any>,
  currentErrors: ErrorType[],
  optionalFields: string[] = [],
  t?: TFunction
) {
  const newErrors: ErrorType[] = [...currentErrors];

  const parseValueToCheck = (value: any) => {
    if (typeof value === 'object' && !value) {
      return value;
    }
    if (typeof value === 'object' && 'id' in value) {
      return value.id;
    }
    if (typeof value === 'object' && 'phone' in value) {
      return value.phone;
    }

    return value;
  };

  Object.keys(formData).forEach((key: string) => {
    // Get value to check depending on the formData[key] type
    let valueToCheck = parseValueToCheck(formData[key]);

    if ((!isNaN(valueToCheck) && valueToCheck === 0) || valueToCheck === false) {
      // If value is 0, it's not an error
      valueToCheck = true;
    }

    // Check if the value is missing and is not in the optional fields
    if (
      !valueToCheck &&
      !optionalFields.includes(key) &&
      key !== 'errors' &&
      !currentErrors.some((elem) => elem.error === key)
    ) {
      newErrors.push({ error: key });
    }

    // Check if the value is negative
    if (
      !valueToCheck &&
      !optionalFields.includes(key) &&
      key !== 'errors' &&
      !currentErrors.some((elem) => elem.error === key)
    ) {
      newErrors.push({ error: key });
    }

    // Check for negative value only if `t` is provided (for translation)
    if (t && typeof valueToCheck === 'string') {
      const parsedValue = parseFloat(valueToCheck);
      if (
        !isNaN(parsedValue) &&
        parsedValue < 0 &&
        !currentErrors.some((elem) => elem.error === key)
      ) {
        newErrors.push({
          error: key,
          description: t('error.minValue', { minValue: 0 }) // Use translation if `t` exists
        });
      }
    }
  });

  return newErrors;
}
