import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_INVOICES_PER_REQUEST, MAX_PAYLOAD_SIZE } from '../../../../../../../constants';
import { Facility } from '../../../../../../../types/entities/facility';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import './styles.scss';
import { IWasteInvoiceBack } from '../../../../../../../types/entities/waste';
import { InputNuvoWastes } from './nuvo/InputNuvoWastes';
import Button from 'components/ui/button/Button';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import UploadFile from '../common/uploadFile/UploadFile';
import { InvoiceToUpload } from 'types/entities/invoice';
import { FormDataUploadInvoices } from '../UploadInvoices';
import useGetRdCodes from '../../createInvoice/components/waste/hooks/useGetRdCodes';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';

type Props = {
  facility: Facility;
  formData: FormDataUploadInvoices;
  setFormData: Dispatch<SetStateAction<FormDataUploadInvoices>>;
  documentsToUpload: InvoiceToUpload[];
  setDocumentsToUpload: Dispatch<SetStateAction<InvoiceToUpload[]>>;
  handleClose: () => void;
};

const UploadWasteInvoices = ({
  facility,
  formData,
  setFormData,
  documentsToUpload,
  setDocumentsToUpload,
  handleClose
}: Props) => {
  const { t } = useTranslation();
  // STATES
  const [loadingButton, setLoadingButton] = useState(false);
  const [sizesBiggerThanMax, setSizesBiggerThanMax] = useState<{ size: number; index: number }[]>(
    []
  );
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { rdCodes, loading } = useGetRdCodes({ lowId: undefined });

  const adaptFrontToBack = (invoice: InvoiceToUpload): IWasteInvoiceBack => {
    const foundPercentage = formData.percentages.find(
      (elem) => elem.facility.id === facility.id
    )?.percentage;
    return {
      file_name: invoice.file.name,
      base_64: invoice.file_url,
      facility_id: invoice.facility_id,
      percentage: foundPercentage ? parseFloat(foundPercentage) : 100
    };
  };

  const handleUploadInvoice = async () => {
    const newErrors = [...errors];
    let sizeBase64 = 0;

    const sizes: number[] = [];
    documentsToUpload.forEach((document) => {
      sizeBase64 = document.file_url.length / (1024 * 1024);
      sizes.push(sizeBase64);
    });

    const sizesBiggerThanMaxList: {
      size: number;
      index: number;
    }[] = [];
    sizes.forEach((elem, index) => {
      if (elem >= MAX_PAYLOAD_SIZE) {
        sizesBiggerThanMaxList.push({
          size: elem,
          index
        });
      }
    });

    setSizesBiggerThanMax(sizesBiggerThanMaxList);

    if (!documentsToUpload || documentsToUpload.length === 0 || sizesBiggerThanMax.length > 0) {
      newErrors.push({
        error: 'file',
        description: t('error.maxPayloadSize')
      });
    }
    if (documentsToUpload.length > MAX_INVOICES_PER_REQUEST) {
      newErrors.push({
        error: 'file',
        description: t('error.maxInvoicesPerRequest')
      });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoadingButton(true);

    const adaptedDocuments = documentsToUpload.map((document) => {
      const backInvoice = adaptFrontToBack(document);
      return backInvoice;
    });

    try {
      // TODO: Call api to upload documents adapted
      setLoadingButton(false);
    } catch (err: any) {
      let responseStatus = 200;
      if (err.response && err.response.status) {
        responseStatus = err.response.status;
      }
      if (responseStatus === 413) {
        setErrors([{ error: 'file', description: t('error.maxPayloadSize') }]);
      } else {
        setErrors([{ error: 'request', description: t('error.uploadInvoices') }]);
      }
      setLoadingButton(false);
    }
  };

  return (
    <>
      <FormWrapper style={{ width: '584px', maxHeight: '45vh', overflowY: 'auto' }}>
        {loading ? (
          <CustomSkeletonLoader count={1} />
        ) : (
          <FormSelect
            label={t('waste.rd')}
            tooltip={t('waste.rdToolTip')}
            icon={'/images/icons/recycle.svg'}
            placeholder={t('waste.writeRd')}
            onChange={onChangeValue('rdCode')}
            value={formData.rdCode}
            options={rdCodes}
          />
        )}
        <UploadFile
          formData={formData}
          documentsToUpload={documentsToUpload}
          setDocumentsToUpload={setDocumentsToUpload}
          facility={facility}
          setFormData={setFormData}
        />
      </FormWrapper>
      <FormButtonSection
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem'
        }}>
        <InputNuvoWastes handleClose={handleClose} />
        <Button
          size='small'
          lookAndFeel='secondary'
          style={{ width: 'fit-content' }}
          text={t('facilityDetail.uploadInvoiceButton')}
          onClick={handleUploadInvoice}
          loading={loadingButton}
          disabled={!!errors.find((error) => error.error === 'percentages')}
        />
      </FormButtonSection>
      {errors.find((error) => error.error === 'request') && (
        <ErrorText>{errors.find((error) => error.error === 'request')?.description}</ErrorText>
      )}
    </>
  );
};

export default UploadWasteInvoices;
