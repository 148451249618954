import React, { useState } from 'react';
import { gradient_color, gray_color2 } from '../../../../../../styles/colors';
import HorizontalBar from '../../../../../ui/horizontalBar/HorizontalBar';
import { useTranslation } from 'react-i18next';
import Icon from 'components/ui/icon/Icon';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import SuccessLabel from 'components/ui/statusLabels/successLabel/SuccessLabel';
import DisabledLabel from 'components/ui/statusLabels/disabledLabel/DisabledLabel';
import ErrorLabel from 'components/ui/statusLabels/errorLabel/ErrorLabel';
import FrameworksCustomizeYearModal from './FrameworkCustomizeYearModal';

type Props = {
  title: string;
  total: number;
  completed: number;
  blocked?: boolean;
  logo?: string;
  date?: string;
  submitted?: boolean;
  config?: boolean;
  id: string | null;
};
const FrameworkProgressBar = ({
  title,
  total,
  completed,
  logo,
  date,
  submitted,
  blocked,
  config,
  id
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const [customizeYearModal, setCustomizeYearModal] = useState(false);
  const dateRenderer = (date: string) => {
    // Check now date. if date is in the past and submitted is false, show error label
    // if date is in the past and submitted is true, show success label
    // if date is in the future, in the next 3 months, show warning label
    // if date is in the future, more than 3 months, show disabled label
    const targetDate = new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'));
    const now = new Date();
    const diffTime = targetDate.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (config && id) {
      return (
        <WarningLabel icon='settings' showIcon={true} onClick={() => setCustomizeYearModal(true)}>
          {t('customizeYourFramework')}
        </WarningLabel>
      );
    }
    if (diffDays < 0) {
      return submitted ? (
        <SuccessLabel showIcon={false}>
          {t('submitted')} {date}
        </SuccessLabel>
      ) : (
        <ErrorLabel showIcon={false}>
          {t('overdue')} {date}
        </ErrorLabel>
      );
    } else if (diffDays / 30 <= 3) {
      return (
        <WarningLabel showIcon={false}>
          {t('deadline')} {date}
        </WarningLabel>
      );
    } else {
      return (
        <DisabledLabel showIcon={false}>
          {t('deadline')} {date}
        </DisabledLabel>
      );
    }
  };

  return (
    <div className='framework-progress flex items-center justify-between gap-4 w-full h-full'>
      <div className='framework-progress__logo flex justify-center items-center'>
        <img src={logo} alt={title} />
      </div>
      <div
        className='flex-col items-start gap-2 h-full justify-center'
        style={{ width: 'calc(100% - 54px - 1rem)' }}>
        <div className='framework-progress__header flex justify-between items-center  w-full'>
          <div className='framework-progress__title flex justify-between items-center gap-2'>
            <div
              className={`${
                blocked
                  ? 'subtitle3-font secondary-text-color'
                  : 'headline4-font on-light-text-color'
              }`}>
              {title}
            </div>
            {blocked ? <Icon icon='locked' color='blocked' /> : null}
          </div>
          {!config && (
            <span
              className={`${
                blocked ? 'subtitle3-font secondary-text-color' : 'headline4-font text-gradient'
              }`}
              style={{
                whiteSpace: 'nowrap',
                textAlign: 'right',
                color: blocked ? '#6e86f7' : gradient_color
              }}>
              {total === 0 ? 0 : Math.round((completed / total) * 100)}%
            </span>
          )}
        </div>
        {(blocked || (!blocked && !config)) && (
          <HorizontalBar
            total={100}
            values={[
              {
                value: Math.round((completed / total) * 100),
                color: blocked ? '#ced2fa' : gradient_color
              }
            ]}
            style={{ borderRadius: '8px', marginLeft: 'auto', background: gray_color2 }}
          />
        )}
        {date && !blocked && dateRenderer(date)}
      </div>
      {customizeYearModal && id && (
        <FrameworksCustomizeYearModal
          modalOpen={customizeYearModal}
          saveFrameworksHandler={() => setCustomizeYearModal(false)}
          title={title}
          frameworkId={id}
        />
      )}
    </div>
  );
};

export default FrameworkProgressBar;
