import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import { NotificationContext } from '../../../../context/notificationContext';
import { UserContext } from '../../../../context/userContext';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { BasicEmployee, Employee } from '../../../../types/entities/employee';
import { EmployeePeriod } from '../../../../types/entities/employeePeriod';
import { IFilter, ISorter, getUrl } from '../../../../utils/url';
import { FilterSection } from '../../../layout/NewFilters/FilterSection';
import { FilterText } from '../../../layout/NewFilters/FilterText';
import { Filters } from '../../../layout/NewFilters/Filters';
import FilterHandlers from '../../../layout/NewFilters/FiltersHandlers';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import CardCTA from '../../../ui/cards/cardCTA/CardCTA';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../ui/modal/Modal';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import useSortingOptions from '../../facilities/hooks/useSortingOptions';
import AddEmployee from './AddEmployee';
import DeleteEmployee from './DeleteEmployee';
import EditEmployee from './EditEmployee';
import useAdapterEmployees from './adapters/getEmployeesTableAdapter';
import ConfirmResend from './confirmResend/ConfirmResend';
import { ModalResendFormTable } from './confirmResend/ModalResendFormTable';
import { EmailFormModalEmployees } from './emailFormModalEmployees/EmailFormModalEmployees';
import useColumns from './hooks/useColumns';
import useFilterOptions from './hooks/useFilterOptions';
import { useGetEmployees } from './hooks/useGetEmployees';
import StatusTagEmployee from './infoTag/StatusTagEmployee';
import AddPeriod from './periods/AddPeriod';
import DeletePeriod from './periods/DeletePeriod';
import EditPeriod from './periods/EditPeriod';
import QRCodeModal from './qrCodeModal/QRCodeModal';
import './styles.scss';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import DownloadCategoryModal from 'components/downloadCategoryModal/DownloadCategoryModal';
import Icon from 'components/ui/icon/Icon';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';

type ExpandType = {
  expand: boolean;
  id: string;
};

function Employees() {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const sortingOptions = useSortingOptions();
  const user = useContext(UserContext);
  const setNotification = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { adaptEmployeeListFromBack2Table } = useAdapterEmployees();

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const [showSendCustomForm, setShowSendCustomForm] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [showConfirmResend, setShowConfirmResend] = useState(false);
  const [sendToPending, setSendToPending] = useState(false);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState<string>('');
  const [expand, setExpand] = useState<ExpandType>({ expand: false, id: '' });
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const defaultSort = {
    ...sortingOptions[0],
    direction: 'desc' as 'desc' | 'asc'
  };

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: defaultSort.id,
      order: defaultSort.direction
    }
  ]);

  const DEFAULT_SORTERS: ISorter[] = [
    {
      field: 'created_at',
      order: 'desc'
    }
  ];

  const {
    data: employees,
    fetchData,
    setData: setEmployees,
    loading,
    firstLoading,
    total,
    setTotal,
    removeElement,
    addElement,
    addElements,
    total2,
    totalTerminated
  } = useGetEmployees({
    filters,
    sorters: DEFAULT_SORTERS
  });

  const filterOptions = useFilterOptions();
  const sideBarFilters = [
    {
      options: filterOptions.transportOptions,
      title: t('employees.transport'),
      field: 'transport_type_period'
    },
    {
      options: filterOptions.pendingResponse,
      title: t('employees.status'),
      field: 'status'
    },
    {
      options: filterOptions.statusOptions,
      title: t('employees.situation'),
      field: 'situation_period'
    },

    {
      options: filterOptions.responseWay,
      title: t('employees.responseWay'),
      field: 'response_medium_period'
    }
  ];

  const employeesWithEmail = employees.filter((employee) => Boolean(employee.email));

  const isSurpassingEmployeeLimit = Boolean(
    foundOrganization && total - totalTerminated >= foundOrganization.limit_employees
  );

  const handleShowAddEmployeeModal = () => setShowAddEmployee(true);
  const handleShowSendCustomFormModal = () => setShowSendCustomForm(true);

  const handleShowQRModal = () => (isSurpassingEmployeeLimit ? setShowQr(false) : setShowQr(true));

  const handleOpenConfirmResendPending = () => {
    if (employeesSelected.length === 0) {
      setSendToPending(true);
    }
    setShowConfirmResend(true);
  };

  /* const handleOpenConfirmResend = () => {
    setShowConfirmResend(true);
  }; */

  const onSelectAllEmployees = () => {
    if (employeesSelected.length === employees.length) {
      setEmployeesSelected([]);
    } else {
      setEmployeesSelected(employees.filter((employee) => Boolean(employee.email)));
    }
  };

  const onRemoveAllEmployees = () => {
    setEmployeesSelected([]);
  };

  const parseDescription = () => {
    if (!flags?.qrEmployees) return t('employees.startDescriptionOld');

    return reactStringReplace(t('employees.startDescription'), '{{qr}}', () => (
      <span className='highlight-text-color pointer' onClick={handleShowQRModal}>
        {t('employees.qr')}
      </span>
    ));
  };

  const addEmployees = (values: Employee[]) => {
    addElements(values);
    setTotal(employees.length);
    onCloseModal();
  };

  const addEmployee = async (employee: Employee) => {
    addElement(employee);
    onCloseModal();
  };

  const editEmployee = async (id: string, employee: BasicEmployee) => {
    setEmployees((prev) => {
      return prev.map((oldEmployee) => {
        if (oldEmployee.id !== id) return oldEmployee;
        return {
          ...oldEmployee,
          id,
          email: employee.email,
          name: employee.name
        };
      });
    });
    onCloseModal();
  };

  const deleteEmployee = (id: string) => {
    removeElement(id);
    dispatch(setNotification(t('notification.deleteEmployee')));
    setEmployeeToDelete('');
    onCloseModal();
  };

  const setPeriods = async (id: string, periods: EmployeePeriod[]) => {
    setEmployees((employees) => {
      const employeeFound = employees.find((employee) => employee.id === id);
      if (!employeeFound) return employees;

      const newEmployee: Employee = {
        ...employeeFound,
        periods
      };

      return employees.map((employee) => {
        if (employee.id === newEmployee.id) return newEmployee;
        return employee;
      });
    });

    onCloseModal();
  };

  const deletePeriod = async (employeeId: string, id: string) => {
    setEmployees((employees) => {
      const employeeFound = employees.find((employee) => employee.id === employeeId);
      if (!employeeFound) return employees;

      const newEmployee: Employee = {
        ...employeeFound,
        periods: employeeFound.periods && employeeFound.periods.filter((period) => period.id !== id)
      };

      return employees.map((employee) => (employee.id === newEmployee.id ? newEmployee : employee));
    });

    onCloseModal();
  };

  const expandableConfig = {
    expandRowByClick: true,
    onExpand: (props: any, record: any) => {
      record.employeeId && setExpand({ expand: props, id: record.employeeId ?? '' });
    }
  };

  useEffect(() => {
    const urlParsed = getUrl('/employees', {
      filters,
      sorters
    });

    setUrl(urlParsed);
  }, [JSON.stringify(filters)]);

  const {
    columns,
    showAddEmployee,
    showAddPeriod,
    periodToDelete,
    employeeToEdit,
    employeeToDelete,
    employeeId,
    periodToEdit,
    periodType,
    employeesSelected,
    setShowAddEmployee,
    setEmployeeToEdit,
    setEmployeesSelected,
    setPeriodToEdit,
    setPeriodToDelete,
    setShowAddPeriod,
    setEmployeeToDelete,
    showModal,
    setShowModal,
    email
  } = useColumns({
    onSelectAllEmployees,
    onRemoveAllEmployees,
    expand,
    employees,
    employeesWithEmail: employeesWithEmail.length
  });

  const adaptedData = adaptEmployeeListFromBack2Table(employees);

  let periodToEditData;

  employees?.forEach((employee) => {
    employee.periods?.forEach((period) => {
      if (period.id === periodToEdit) periodToEditData = period;
    });
  });

  const getClassName = (record: any) => {
    if (
      record?.status === 'terminated' ||
      record?.status === 'inactive' ||
      record?.status === 'loading'
    )
      return 'disabled';
    return '';
  };

  const onCloseModal = () => {
    setShowAddEmployee(false);
    setShowAddPeriod(false);
    setShowSendCustomForm(false);
    setEmployeesSelected([]);
    setShowConfirmResend(false);
    setSendToPending(false);
    setEmployeeToEdit(null);
    setPeriodToEdit('');
    setEmployeeToDelete('');
    setPeriodToDelete('');
    setShowQr(false);
    setShowModal(false);
  };

  const pendingEmployees = employees.filter((employee) => employee.status === 'loading');
  return (
    <section className='employees'>
      <div className='employees__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('employees.title')}</h3>
        <Breadcrumb />
      </div>
      <CardCTA>
        <CardCTA.Header>
          <span className='headline4-font'> {t('employees.start')}</span>
          <span className='subtitle3-font'>{parseDescription()}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel={isSurpassingEmployeeLimit ? 'blocked' : 'primary'}
            text={t('employees.viewForm')}
            size='small'
            onClick={handleShowSendCustomFormModal}
          />
          <Button
            lookAndFeel={isSurpassingEmployeeLimit ? 'blocked' : 'secondary'}
            text={t('employees.addManual')}
            size='small'
            onClick={handleShowAddEmployeeModal}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <InfiniteList
          data={adaptedData}
          fetchData={fetchData}
          columns={columns}
          loading={loading}
          firstLoading={firstLoading}
          total={total}
          rowClassName={(record) => getClassName(record)}
          header={
            <>
              <div className='flex gap-x-2 items-center'>
                <FilterText field='search' type='il' placeholder={t('employees.inputFilter')} />
                <Filters.Menu>
                  {sideBarFilters?.map((section) => (
                    <FilterSection.Multiple
                      key={`section-${section.title}`}
                      title={section.title}
                      field={section.field}
                      type='in'
                      options={Object.values(section.options).map((elem: any) => ({
                        label:
                          section.field === 'situation_period' || section.field === 'status' ? (
                            <StatusTagEmployee
                              status={elem.id}
                              text={t(`employees.${elem.id}`)}></StatusTagEmployee>
                          ) : (
                            elem.name
                          ),
                        value: elem.id
                      }))}
                    />
                  ))}
                </Filters.Menu>

                <FilterHandlers blacklistedFilters={{ all: ['name'] }} />
              </div>

              <div className='email-actions'>
                <TooltipWrapper text={t('general.downloadButtonEmailTooltip')}>
                  <Button
                    lookAndFeel={'primary'}
                    iconNode={<Icon icon='download' color='white' />}
                    text={t('general.download')}
                    size='small'
                    height='small'
                    onClick={() => setShowDownloadModal(true)}
                  />
                </TooltipWrapper>
                <Button
                  onClick={handleOpenConfirmResendPending}
                  text={
                    employeesSelected.length
                      ? t('employees.resendSelected') +
                        ` (${employeesSelected.length}/${employeesWithEmail.length})`
                      : t('employees.resendPending')
                  }
                  height='small'
                  size='small'
                  lookAndFeel={'secondary'}
                />
                <TotalLegend total={total} total2={total2} loading={loading} i18key='employees' />
              </div>
            </>
          }
          expandable={expandableConfig}
        />
      </Filters.Root>
      <Modal show={showAddEmployee} onClose={onCloseModal} width='700px' maxWidth='700px'>
        <AddEmployee addEmployee={addEmployee} employees={employees} type='in_itinere' />
      </Modal>

      <Modal show={showSendCustomForm} onClose={onCloseModal} width='650px' maxWidth='650px'>
        <EmailFormModalEmployees addEmployees={addEmployees} close={onCloseModal} />
      </Modal>

      <Modal show={!!employeeToEdit} onClose={onCloseModal} width='500px' maxWidth='500px'>
        {employeeToEdit && (
          <EditEmployee employeeToEdit={employeeToEdit} editEmployee={editEmployee} />
        )}
      </Modal>

      <Modal show={!!employeeToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <DeleteEmployee
          deleteEmployee={deleteEmployee}
          employeeToDelete={employeeToDelete}
          user={user}
        />
      </Modal>

      <Modal show={showAddPeriod} onClose={onCloseModal} width='700px' maxWidth='700px'>
        {employeeId && <AddPeriod setPeriods={setPeriods} employeeId={employeeId} />}
      </Modal>

      <Modal show={!!periodToEdit} onClose={onCloseModal} width='700px' maxWidth='700px'>
        {employeeId && periodToEditData && (
          <EditPeriod
            setPeriods={setPeriods}
            employeeId={employeeId}
            periodToEdit={periodToEditData}
            type={periodType}
          />
        )}
      </Modal>
      <Modal show={!!periodToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        {employeeId && (
          <DeletePeriod
            deletePeriod={deletePeriod}
            employeeId={employeeId}
            periodId={periodToDelete}
          />
        )}
      </Modal>

      <Modal show={showConfirmResend} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <FormHeader
          title={t('employees.confirmResend').replace(
            '{{employees}}',
            sendToPending ? pendingEmployees.length.toString() : employeesSelected.length.toString()
          )}
          description={t('employees.confirmResendDescription')}
        />

        <ConfirmResend
          onConfirm={onCloseModal}
          sendToPending={sendToPending}
          emailsToSend={
            sendToPending
              ? pendingEmployees.map((employee) => employee.email ?? '')
              : employeesSelected.map((employee) => employee?.email ?? '')
          }
        />
      </Modal>
      <Modal show={showQr} onClose={onCloseModal} width='650px' maxWidth='650px'>
        <QRCodeModal />
      </Modal>
      <Modal show={showModal} onClose={onCloseModal} width='600px' maxWidth='600px'>
        <ModalResendFormTable email={email} onConfirm={onCloseModal} />
      </Modal>
      <Modal show={showDownloadModal} onClose={() => setShowDownloadModal(false)} width='500px'>
        <DownloadCategoryModal category={'employees'} onClose={() => setShowDownloadModal(false)} />
      </Modal>
    </section>
  );
}

export default Employees;
